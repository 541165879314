import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../components/Accordion';
import Toggle from '../../../../../../../components/Toggle';
import ProtectedComponent from '../../../../../../../router/components/ProtectedComponent';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import rcuEnums from '../../../../../../../utilities/enums/rcuEnums';
import { RCUContext } from '../../../../../../../utilities/services/contexts';
import BiometricAuthenticationRCU from './components/BiometricAuthenticationRCU';
import DocumentAuthenticationRCU from './components/DocumentAuthenticationRCU';
import IdentityVerificationRCU from './components/IdentityVerificationRCU';
import './styles.scss';

const KYCRequests = ({ namePrefix }) => {
  const { change, batch } = useForm();
  const [selectAll, setSelectAll] = useState(false);
  const [manualOpen, setManualOpen] = useState(false);
  const {
    initialConfiguration: {
      documentAuthenticationEnabled: docsAuthVisible,
      biometricAuthenticationEnabled: biometricAuthVisible,
    },
  } = useContext(RCUContext);

  const { values } = useFormState();
  const kycRequests = values?.[namePrefix]?.kycRequests || values.kycRequests;
  const { allEnabled, runVerification, ...rest } = kycRequests || {};

  const kycRequestsSections = rest;

  const biometricAuthEnabled = kycRequestsSections?.biometricAuthentication?.isEnabled;
  const documentAuthEnabled = kycRequestsSections?.documentAuthentication?.isEnabled;
  const identityVerEnabled = kycRequestsSections?.identityVerification?.isEnabled;

  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  useEffect(() => {
    if (selectAll) {
      batch(() => {
        change(`${namePrefixWithDot}kycRequests.biometricAuthentication.isEnabled`, true && biometricAuthVisible);
        change(`${namePrefixWithDot}kycRequests.documentAuthentication.isEnabled`, true && docsAuthVisible);
        change(`${namePrefixWithDot}kycRequests.identityVerification.isEnabled`, true);
      });
    }
  }, [selectAll, batch, change]);

  const arrayOfEnabled = [
    { enabled: identityVerEnabled, visible: true },
    { enabled: documentAuthEnabled, visible: docsAuthVisible },
    { enabled: biometricAuthEnabled, visible: biometricAuthVisible },
  ];

  const allSelectedSeparately = useMemo(() => {
    if (arrayOfEnabled.some(item => item?.visible === undefined)) return false;
    return arrayOfEnabled.filter(kycRequestType => kycRequestType.visible).every(({ enabled }) => enabled);
  }, [arrayOfEnabled]);

  useEffect(() => {
    if (allSelectedSeparately) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [allSelectedSeparately]);

  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      batch(() => {
        change(`${namePrefixWithDot}kycRequests.biometricAuthentication.isEnabled`, false && biometricAuthVisible);
        change(`${namePrefixWithDot}kycRequests.documentAuthentication.isEnabled`, false && docsAuthVisible);
        change(`${namePrefixWithDot}kycRequests.identityVerification.isEnabled`, false);
      });
    }
    setSelectAll(prev => !prev);
    setManualOpen(!selectAll);
  }, [batch, change, selectAll]);

  const preventBubbling = e => {
    e.stopPropagation();
  };

  return (
    <Accordion
      accordionindex={rcuEnums.ACCORDION_INDEXES.KYC_REQUESTS}
      accordionOpen={manualOpen}
      title={
        <>
          <h3>KYC Requests</h3>
          <span className="ickyc-spacer" />
          <div onClick={preventBubbling}>
            <Toggle preview value={selectAll} onChange={handleSelectAll} label="Enable All" />
          </div>
        </>
      }
    >
      <div className="ickyc-kyc-requests">
        <ProtectedComponent
          permissionGroup={authEnums.PERMISSION_GROUP.PERSONAL_INFORMATION}
          requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.requestClientUpdate]}
        >
          <IdentityVerificationRCU namePrefix={namePrefix} />
        </ProtectedComponent>
        {docsAuthVisible && (
          <ProtectedComponent
            permissionGroup={authEnums.PERMISSION_GROUP.IDENTITY_DOCUMENTS}
            requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.requestClientUpdate]}
          >
            <DocumentAuthenticationRCU namePrefix={namePrefix} />
          </ProtectedComponent>
        )}

        {biometricAuthVisible && (
          <ProtectedComponent
            permissionGroup={authEnums.PERMISSION_GROUP.BIOMETRIC_AUTHENTICATION}
            requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.requestClientUpdate]}
          >
            <BiometricAuthenticationRCU namePrefix={namePrefix} />
          </ProtectedComponent>
        )}
      </div>
    </Accordion>
  );
};

export default KYCRequests;
